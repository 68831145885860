import fb from "../../assets/images/contactImage/facebook.png"
import insta from "../../assets/images/contactImage/insta.png"
import linkedin from "../../assets/images/contactImage/linkedin.png"
import twitter from "../../assets/images/contactImage/twitter.png"
import gmail from "../../assets/images/contactImage/gmail.png"
import github from "../../assets/images/contactImage/github.png"

const contactMethonds = [
    {
        title:"Gmail",
        logo:gmail,
        link:"mailto: aman.shrestha@rochester.edu",
    },
    {
        title:"Github",
        logo:github,
        link:"https://github.com/amnshrestha",
    },
    {
        title:"LinkedIn",
        logo:linkedin,
        link:"https://www.linkedin.com/in/aman-shrestha/",
    },
    {
        title:"Facebook",
        logo:fb,
        link:"https://www.facebook.com/Awsmamn",
    },
    {
        title:"Instagram",
        logo:insta,
        link:"https://www.instagram.com/belloz_batman/",
    },
    {
        title:"Twitter",
        logo:twitter,
        link:"https://twitter.com/AmanShrestha720",
    },


]

export default contactMethonds